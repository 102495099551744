import React, { useState, useEffect, useRef, useCallback } from "react";
import timeupBuzzer from "./assets/audio/timeup_buzzer.mp3";
import popSound from "./assets/audio/pop.mp3";
import correctBuzzer from "./assets/audio/correct_buzzer.mp3";
import wrongBuzzer from "./assets/audio/wrong_buzzer.mp3";
import styles from "./quiz.module.css";
import maruImage from "./assets/image/maru.svg";
import batsuImage from "./assets/image/batsu.svg";
import questionSound from "./assets/audio/question.mp3";

interface Question {
  quiz: string;
  answer: string;
}

const time: number = 30;

const QuizPage: React.FC = () => {
  const [unusedQuestions, setUnusedQuestions] = useState<Question[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<Question | null>(null);

  const [timeLeft, setTimeLeft] = useState<number>(time);
  const [timerRunning, setTimerRunning] = useState<boolean>(false);

  const [showAnswer, setShowAnswer] = useState<boolean>(false);

  const [currentAnswer, setCurrentAnswer] = useState<string>("");

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const [quizStarted, setQuizStarted] = useState<boolean>(false);

  const handleStartQuiz = () => {
    setQuizStarted(true);
    startNewQuestion();
  };

  useEffect(() => {
    fetch("/quiz_datas.csv")
      .then((response) => response.text())
      .then((data) => {
        const parsedQuestions = parseCSV(data);
        const selectedQuestions = getRandomQuestions(parsedQuestions, 10);
        setUnusedQuestions(selectedQuestions);
      })
      .catch((error) => {
        console.error("CSVファイルの読み込み中にエラーが発生しました:", error);
      });
  }, []);

  // タイマーのセットアップ
  useEffect(() => {
    if (timerRunning) {
      intervalRef.current = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [timerRunning]); // timerRunningを依存配列に追加

  const playAnswerSound = useCallback(() => {
    const audio = new Audio(
      currentAnswer === "true" ? correctBuzzer : wrongBuzzer
    );
    audio.play().catch((error) => {
      console.warn("音声の再生がブロックされました:", error);
    });
  }, [currentAnswer]);

  const playQuestionSound = () => {
    const audio = new Audio(questionSound);
    audio.play().catch((error) => {
      console.warn("音声の再生がブロックされました:", error);
    });
  };

  useEffect(() => {
    if (showAnswer) {
      playAnswerSound();
    }
  }, [showAnswer, playAnswerSound]);

  // タイマーがゼロになったときの処理
  useEffect(() => {
    if (timeLeft <= 3 && timeLeft !== 0 && timerRunning) {
      playPopSound();
    }
    if (timeLeft === 0 && timerRunning) {
      setTimerRunning(false);
      playTimeUpBuzzer();

    }
  }, [timeLeft, timerRunning]);

  const playPopSound = () => {
    const audio = new Audio(popSound);
    audio.play().catch((error) => {
      console.warn("音声の再生がブロックされました:", error);
    });
  };

  const playTimeUpBuzzer = () => {
    const audio = new Audio(timeupBuzzer);
    audio.play().catch((error) => {
      console.warn("音声の再生がブロックされました:", error);
    });
  };

  const getRandomQuestions = (questions: Question[], n: number): Question[] => {
    const shuffled = [...questions];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled.slice(0, Math.min(n, shuffled.length));
  };

  const startNewQuestion = () => {
    setShowAnswer(false);
    setTimeLeft(time);
    setTimerRunning(true);

    const randomIndex = Math.floor(Math.random() * unusedQuestions.length);
    const question = unusedQuestions[randomIndex];
    setCurrentQuestion(question);

    const updatedUnusedQuestions = [...unusedQuestions];
    updatedUnusedQuestions.splice(randomIndex, 1);
    setUnusedQuestions(updatedUnusedQuestions);

    playQuestionSound();
  };

  const handleNextButtonClick = () => {
    if (currentQuestion) {
      setCurrentAnswer(currentQuestion.answer);
    } else {
      setCurrentAnswer("答えがありません");
    }
    setShowAnswer(true);
    setCurrentQuestion(null);
  };

  const handleNextQuestionClick = () => {
    if (unusedQuestions.length > 0) {
      startNewQuestion();
    } else {
      alert("終了");
    }
  };

  const parseCSV = (data: string): Question[] => {
    const lines = data.trim().split("\n");
    const result: Question[] = [];
    for (let i = 1; i < lines.length; i++) {
      const [quiz, answer] = lines[i].split(",");
      result.push({ quiz: quiz.trim(), answer: answer.trim() });
    }
    return result;
  };

  return (
    <div className={styles.quizContainer}>
      {!quizStarted ? (
        <button onClick={handleStartQuiz} className={styles.button}>
          クイズを開始
        </button>
      ) : (
        <>
          {!showAnswer && currentQuestion && (
            <div className={styles.contentWrapper}>
              <div className={styles.card}>
                <h2>{currentQuestion.quiz}</h2>
                <p>残り時間: {timeLeft}</p>
              </div>

                <button
                  onClick={handleNextButtonClick}
                  className={styles.button}
                >
                  次へ
                </button>

            </div>
          )}
          {showAnswer && (
            <div className={styles.contentWrapper}>
              <div className={styles.card}>
                <h2>{currentAnswer === "true" ? "正解" : "不正解"}</h2>
                <img
                  src={currentAnswer === "true" ? maruImage : batsuImage}
                  alt={currentAnswer === "true" ? "正解" : "不正解"}
                  className={styles.answerImage}
                />
              </div>
              <button
                onClick={handleNextQuestionClick}
                className={styles.button}
              >
                次の問題へ
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default QuizPage;
